import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import { autobind } from "react-decoration";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { page } from "components/page";
import navigate from "lib/navigate";
import ReactHtmlParser from "react-html-parser";
import ReactDOM from 'react-dom';
import qs from "query-string";
import axios from "axios";

const getSendURLAPI = gql`
  query getSendURLAPI($form: SendURLForm) {
    result: getSendURLAPI(Input: $form) {
      redirectMode
      url
      view
      ruleFlag
      normalCampusFlag
      dataListCampusFlag
      journalListCampusFlag
      searchListCampusFlag
      jcrCampusFlag
      loginTimeFlag
      sendURLFlag
      guestFlag
      detailFlag
      loginFlag
      hyproxyURL
      ezproxyURL
      uid
      pass
      pin
      code
      custSubmitForm
    }
  }
`;

@withI18next(["common"])
@page
class SendURLApiV3 extends React.Component {
  constructor(props) {
    super(props);
    let webAppName = props.appStore.globalConfig["jumperrwd.common.webAppName"] || "";
    let hyssoConfig = props.appStore.globalConfig["jumperrwd.hysso.clientConfig"] || "";
    this.state = {
      auth: props.readerStore.auth,
      webAppName: webAppName,
      hyssoConfig: hyssoConfig
    };
  }

  componentDidMount() {
    this.sendURLApi(this.props);
  }

  componentWillReceiveProps(props) {
    if (this.state.auth !== props.readerStore.auth) {
      this.setState({ auth: props.readerStore.auth });
      this.sendURLApi(props);
    }
  }

  @autobind
  sendURLApi(props) {
    let canSubimt = true;
    let { search } = props;
    let params = { ...search };
    let {
      journal_id,
      dbid,
      type,
      issn,
      url,
      force,
      pr,
      cno,
      clicktype,
      hyintid,
      linktype,
      view,
      logintime,
      mode,
      ticket,
      uid,
      pass,
      nez,
      title,
    } = params;

    if (journal_id !== undefined && journal_id !== "") {
      let parsed = parseInt(journal_id);
      if (isNaN(parsed)) {
        canSubimt = false;
        navigate("/");
      }
    } else {
      journal_id = "0";
    }
    if (dbid !== undefined && dbid !== "") {
      if (this.isSpecialChar(dbid) || dbid.length > 9) {
        canSubimt = false;
        navigate("/");
      }
    }
    if (type !== undefined && type !== "") {
      if (
        type !== "db" &&
        type !== "journal" &&
        type !== "ebook" &&
        type !== "network" &&
        type !== "jcr"
      ) {
        let parsed = parseInt(type);
        if (isNaN(parsed)) {
          canSubimt = false;
          navigate("/");
        }
      }
    }

    if (issn !== undefined && issn !== "") {
      if (!this.isValidISBN(issn) && !this.isValidISSN(issn)) {
        canSubimt = false;
        navigate("/");
      }
    }

    if (url !== undefined && url !== "" && !this.checkSecurityPattern2(url)) {
      canSubimt = false;
      navigate("/");
    }

    if (force !== undefined && force !== "") {
      let parsed = parseInt(force);
      if (isNaN(parsed)) {
        canSubimt = false;
        navigate("/");
      }
    }

    if (pr !== undefined && pr !== "") {
      if (pr !== "hy") {
        canSubimt = false;
        navigate("/");
      }
    }

    if (canSubimt) {
      client.jumperrwdClient
        .query({
          query: getSendURLAPI,
          fetchPolicy: "network-only",
          variables: {
            form: {
              type: type,
              url: url,
              cno: cno,
              journalID: parseInt(journal_id),
              dbID: dbid,
              clickType: clicktype,
              hyintID: hyintid,
              linkType: linktype,
              view: view === "true",
              loginTime: logintime,
              issn: issn,
              mode: mode,
              ticket: ticket,
              uid: uid,
              pass: pass,
              nez: nez,
              title: title,
              pr: pr,
            },
          },
        })
        .then((res) => {
          let { result } = res.data;
          if (result.redirectMode === 0) {
            if (
              result.view ||
              result.ruleFlag ||
              result.normalCampusFlag ||
              result.dataListCampusFlag ||
              result.journalListCampusFlag ||
              result.searchListCampusFlag ||
              result.jcrCampusFlag ||
              result.loginTimeFlag ||
              result.sendURLFlag ||
              result.guestFlag ||
              result.detailFlag ||
              this.state.auth
            ) {
              window.location.href = result.url;
            } else {
              if(this.state.webAppName === "fcu" || this.state.hyssoConfig !== ""){
                let sendURLApiV3Params = {
                  url: url,
                };
                let oauthParams = {
                  login: "true",
                  url: ["/sendURLApiV3", qs.stringify(sendURLApiV3Params)].join("?"),
                };
                navigate(["/oauth", qs.stringify(oauthParams)].join("?"));
              } else {
                this.props.appStore.setSendURL(result.url);
                this.props.appStore.openLogin();
              }
            }
          } else if (result.redirectMode === 1) {
            if (result.hyproxyURL !== "") {
              /*let form = (
                <>
                    <form
                    name="auto"
                    id="auto"
                    method="post"
                    action={result.hyproxyURL}>
                    <input type="hidden" name="url" value={result.url} />
                    <input type="hidden" name="uid" value={result.uid} />
                    <input type="hidden" name="pwd" value={result.pass} />
                    <input type="submit" value="送出" style="display:none" />
                    </form>
                </>
                );*/
              if (result.custSubmitForm) {
                let container = this.container;
                ReactDOM.render(<>{ReactHtmlParser(result.custSubmitForm)}</>, container);
                document.getElementById('auto').submit();
              } else {
                let form = document.createElement("form");
                form.name = "auto";
                form.id = "auto";
                form.method = "POST";
                form.action = result.hyproxyURL;
                let element1 = document.createElement("input");
                element1.type = "hidden";
                element1.name = "url";
                element1.value = result.url;
                form.appendChild(element1);
                let element2 = document.createElement("input");
                element2.type = "hidden";
                element2.name = "uid";
                element2.value = result.uid;
                form.appendChild(element2);
                let element3 = document.createElement("input");
                element3.type = "hidden";
                element3.name = "pwd";
                element3.value = result.pass;
                form.appendChild(element3);
                let element4 = document.createElement("input");
                element4.type = "submit";
                element4.value = "送出";
                element4.style = "display:none";
                form.appendChild(element4);
                document.body.appendChild(form);
                form.submit();
              }
            } else if (result.ezproxyURL !== "") {
              /*let form = (
                <>
                    <form
                    name="auto"
                    id="auto"
                    method="post"
                    action={result.ezproxyURL}>
                    <input type="hidden" name="url" value={result.url} />
                    {result.uid !== "" && (
                        <>
                        <input type="hidden" name="user" value={result.uid} />
                        <input type="hidden" name="pass" value={result.pass} />
                        </>
                    )}
                    <input type="submit" value="送出" style="display:none" />
                    </form>
                </>
                );*/
              if (result.custSubmitForm) {
                let container = this.container;
                ReactDOM.render(<div>{ReactHtmlParser(result.custSubmitForm)}</div>, container);
                document.getElementById('auto').submit();
              } else {
                let form = document.createElement("form");
                form.name = "auto";
                form.id = "auto";
                form.method = "POST";
                form.action = result.ezproxyURL;
                let element1 = document.createElement("input");
                element1.type = "hidden";
                element1.name = "url";
                element1.value = result.url;
                form.appendChild(element1);
                if (result.uid !== "") {
                  let element2 = document.createElement("input");
                  element2.type = "hidden";
                  element2.name = "user";
                  element2.value = result.uid;
                  form.appendChild(element2);
                  let element3 = document.createElement("input");
                  element3.type = "hidden";
                  element3.name = "pass";
                  element3.value = result.pass;
                  form.appendChild(element3);
                }
                let element4 = document.createElement("input");
                element4.type = "submit";
                element4.value = "送出";
                element4.style = "display:none";
                form.appendChild(element4);
                document.body.appendChild(form);
                form.submit();
              }
            } else if (result.url !== "") {
              /*let form = (
                <>
                    <form
                    name="auto"
                    id="auto"
                    method="post"
                    action={result.url}>
                    <input type="hidden" name="code" value={result.code} />
                    <input type="hidden" name="pin" value={result.pin} />
                    <input type="submit" value="送出" style="display:none" />
                    </form>
                </>
                );
                document.body.appendChild(form);
                form.submit();*/
              if (result.custSubmitForm) {
                let container = this.container;
                ReactDOM.render(<div>{ReactHtmlParser(result.custSubmitForm)}</div>, container);
                document.getElementById('auto').submit();
              } else {
                let form = document.createElement("form");
                form.name = "auto";
                form.id = "auto";
                form.method = "POST";
                form.action = result.url;
                let element1 = document.createElement("input");
                element1.type = "hidden";
                element1.name = "code";
                element1.value = result.code;
                form.appendChild(element1);
                let element2 = document.createElement("input");
                element2.type = "hidden";
                element2.name = "pin";
                element2.value = result.pin;
                form.appendChild(element2);
                let element3 = document.createElement("input");
                element3.type = "submit";
                element3.value = "送出";
                element3.style = "display:none";
                form.appendChild(element3);
                document.body.appendChild(form);
                form.submit();
              }
            }
          } else if (result.redirectMode === 2) {
            if (result.loginFlag) {
              this.props.appStore.setSendURL(result.url);
              this.props.appStore.openLogin();
            } else {
              let container = this.container;
              ReactDOM.render(
                <div>{ReactHtmlParser(result.custSubmitForm)}</div>,
                container
              );
              document.getElementById("auto").submit();
            }
          }
        });
    }
  }

  @autobind
  isSpecialChar(str) {
    let regEx = new RegExp(
      "[ _`~!@#$%^&*()+=|{}':;',\\[\\].<>/?~！@#￥%……&*（）——+|{}【】‘；：”“’。，、？]|\n|\r|\t"
    );
    return regEx.test(str);
  }

  @autobind
  isValidISBN(isbn) {
    let flag = true;
    isbn = isbn.replace(new RegExp("-","gm"), ""); //拿掉-
    flag = flag && (isbn.length === 13 || isbn.length === 10); //應該等於13或10
    isbn = isbn.replace(/[\d|X|x]/g, ""); //拿掉0-9跟xX
    flag = flag && isbn === ""; //應該會是空的
    return flag;
  }

  @autobind
  isValidISSN(issn) {
    let flag = true;
    issn = issn.replace(new RegExp("-","gm"), ""); //拿掉-
    flag = flag && issn.length === 8; //應該等於8
    issn = issn.replace(/[\d|X|x]/g, ""); //拿掉0-9跟xX
    flag = flag && issn === ""; //應該會是空的
    return flag;
  }

  @autobind
  checkSecurityPattern2(str) {
    if (!this.checkSecurityArray(str)) {
      return false;
    } else {
      let patternList = [];
      patternList.push("[\\s\\S]*<(\"[^\"]*\"|'[^']*'|[^'\">])*>[\\s\\S]*");

      let matches = true;

      patternList.map((patternStr) => {
        let regEx = new RegExp(patternStr);

        if (regEx.test(str)) {
          matches = false; //有matches到表示不安全
        }
        return "";
      });

      return matches;
    }
  }

  @autobind
  checkSecurityArray(str) {
    let tmp = str.toLowerCase();
    let patternList = [];
    patternList.push("onclick");
    patternList.push("oncontextmenu");
    patternList.push("ondblclick");
    patternList.push("onmousedown");
    patternList.push("onmouseenter");
    patternList.push("onmouseleave");
    patternList.push("onmousemove");
    patternList.push("onmouseover");
    patternList.push("onmouseout");
    patternList.push("onmouseup");
    patternList.push("onkeydown");
    patternList.push("onkeypress");
    patternList.push("onkeyup");
    patternList.push("():;");

    let result = true;

    patternList.map((patternStr) => {
      if (tmp.indexOf(patternStr) >= 0) {
        result = false;
      }
      return "";
    });

    return result;
  }

  render() {
    let { t, isprivate, i18n } = this.props;
    return (
      <Layout t={t} i18n={i18n} isprivate={isprivate} hiddenBg={true}>
        <div
          ref={(c) => {
            this.container = c;
          }}
        />
      </Layout>
    );
  }
}

export default SendURLApiV3;
